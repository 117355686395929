import { MenuProps } from "antd";
import CommonIcons from "../assets/icons";
import { TOption } from "../interface";
import baseRouter from "../router/baseRouter";
import Icon from "@ant-design/icons/lib/components/Icon";
import CommonImages from "../assets/images";

export const ENTITY_TYPE = "DEVICE";
export const ASSET_TYPE = "ASSET";
export const ID_STATION = {
  lu_quet: {
    lq1: process.env.REACT_APP_LU_QUET_1,
    lq2: process.env.REACT_APP_LU_QUET_2,
    lq3: process.env.REACT_APP_LU_QUET_3,
    lq4: process.env.REACT_APP_LU_QUET_4,
    lq5: process.env.REACT_APP_LU_QUET_5,
  },
  sat_lo: {
    sl1: process.env.REACT_APP_SAT_LO_1,
    sl2: process.env.REACT_APP_SAT_LO_2,
    sl3: process.env.REACT_APP_SAT_LO_3,
    sl4: process.env.REACT_APP_SAT_LO_4,
    sl5: process.env.REACT_APP_SAT_LO_5,
  },
};

export const ASSET_CDRC = process.env.REACT_APP_ASSET_CDRC;
export const ASSET_LMMN = process.env.REACT_APP_ASSET_LMMN;
export const CDRC = "CDRC";
export const LMMN = "LMMN";

export const menuCustomer: MenuProps["items"] = [
  {
    label: "Giám sát tổng quan",
    key: "",
    icon: <Icon component={() => <img src={CommonImages.home} />} />,
    className: "!text-white !m-0 !rounded-none !w-full ",
  },
  {
    label: "Trạm cảnh báo lũ quét",
    key: baseRouter.stationLMMN.split("/")[1],
    icon: <Icon component={() => <img src={CommonImages.tabLMMN} />} />,
    className: "!text-white !m-0 !rounded-none !w-full ",
  },
  {
    label: "Trạm cảnh báo sạt lở đất",
    key: baseRouter.stationCDRC.split("/")[1],
    icon: <Icon component={() => <img src={CommonImages.tabCDRC} />} />,
    className: "!text-white !m-0 !rounded-none !w-full ",
  },
  {
    label: "Quản lý hệ thống",
    key: "manageSystems",
    icon: <Icon component={() => <img src={CommonImages.setting} />} />,
    className: "!text-white !m-0 !rounded-none !w-full",
    theme: "dark",
    children: [
      {
        label: "Trạm đo",
        key: `${baseRouter.station.split("/")[1]}`,
        className: "!text-white !m-0 !rounded-none !w-full ",
        theme: "dark",
        children: [
          {
            label: "Danh sách trạm đo",
            key: `${baseRouter.station.split("/")[1]}`,
            className: "!text-white !m-0 !rounded-none !w-full ",
          },
          // {
          //   label: "Danh sách camera",
          //   key: "camera",
          //   className: "!text-white !m-0 !rounded-none !w-full ",
          // },
        ],
      },
      // {
      //   label: "Ticket sự cố",
      //   key: `${baseRouter.tickets.split("/")[1]}`,
      //   className: "!text-white !m-0 !rounded-none !w-full ",
      // },
      // {
      //   label: "SMS đa kênh",
      //   key: `${baseRouter.sms.split("/")[1]}`,
      //   className: "!text-white !m-0 !rounded-none !w-full ",
      //   children: [
      //     {
      //       label: "Cấu hình tin nhắn",
      //       key: `${baseRouter.sms.split("/")[1]}`,
      //       className: "!text-white !m-0 !rounded-none !w-full",
      //     },
      //     {
      //       label: "Cấu hình số điện thoại",
      //       key: `${baseRouter.sms.split("/")[1]}/${
      //         baseRouter.smsGroupPhone.split("/")[1]
      //       }`,
      //       className: "!text-white !m-0 !rounded-none !w-full",
      //     },
      //   ],
      // },
    ],
  },
];

export const menuConfig: MenuProps["items"] = [
  {
    label: "Giám sát tổng quan",
    key: "",
    icon: <Icon component={() => <img src={CommonImages.home} />} />,
    className: "!text-white !m-0 !rounded-none !w-full ",
  },
  {
    label: "Trạm cảnh báo lũ quét",
    key: baseRouter.stationLMMN.split("/")[1],
    icon: <Icon component={() => <img src={CommonImages.tabLMMN} />} />,
    className: "!text-white !m-0 !rounded-none !w-full ",
  },
  {
    label: "Trạm cảnh báo sạt lở đất",
    key: baseRouter.stationCDRC.split("/")[1],
    icon: <Icon component={() => <img src={CommonImages.tabCDRC} />} />,
    className: "!text-white !m-0 !rounded-none !w-full ",
  },

  {
    label: "Quản lý hệ thống",
    key: "manageSystems",
    icon: <Icon component={() => <img src={CommonImages.setting} />} />,
    className: "!text-white !m-0 !rounded-none !w-full",
    theme: "dark",
    children: [
      {
        label: "Trạm đo",
        key: `${baseRouter.station.split("/")[1]}`,
        className: "!text-white !m-0 !rounded-none !w-full ",
        theme: "dark",
        children: [
          {
            label: "Danh sách trạm đo",
            key: `${baseRouter.station.split("/")[1]}`,
            className: "!text-white !m-0 !rounded-none !w-full ",
          },
          // {
          //   label: "Danh sách camera",
          //   key: "camera",
          //   className: "!text-white !m-0 !rounded-none !w-full ",
          // },
        ],
      },
      {
        label: "Ticket sự cố",
        key: `${baseRouter.tickets.split("/")[1]}`,
        className: "!text-white !m-0 !rounded-none !w-full ",
      },
      // {
      //   label: "SMS đa kênh",
      //   key: `${baseRouter.sms.split("/")[1]}`,
      //   className: "!text-white !m-0 !rounded-none !w-full ",
      //   children: [
      //     {
      //       label: "Cấu hình tin nhắn",
      //       key: `${baseRouter.sms.split("/")[1]}`,
      //       className: "!text-white !m-0 !rounded-none !w-full",
      //     },
      //     {
      //       label: "Cấu hình số điện thoại",
      //       key: `${baseRouter.sms.split("/")[1]}/${
      //         baseRouter.smsGroupPhone.split("/")[1]
      //       }`,
      //       className: "!text-white !m-0 !rounded-none !w-full",
      //     },
      //   ],
      // },
    ],
  },
];

export const menuSettings: MenuProps["items"] = [
  {
    label: "Giám sát tổng quan",
    key: "",
    icon: <Icon component={() => <img src={CommonImages.home} />} />,
    className: "!text-white !m-0 !rounded-none !w-full ",
  },
  {
    label: "Trạm cảnh báo lũ quét",
    key: baseRouter.stationLMMN.split("/")[1],
    icon: <Icon component={() => <img src={CommonImages.tabLMMN} />} />,
    className: "!text-white !m-0 !rounded-none !w-full ",
  },
  {
    label: "Trạm cảnh báo sạt lở đất",
    key: baseRouter.stationCDRC.split("/")[1],
    icon: <Icon component={() => <img src={CommonImages.tabCDRC} />} />,
    className: "!text-white !m-0 !rounded-none !w-full ",
  },

  {
    label: "Quản lý hệ thống",
    key: "manageSystems",
    icon: <Icon component={() => <img src={CommonImages.setting} />} />,
    className: "!text-white !m-0 !rounded-none !w-full",
    theme: "dark",
    children: [
      {
        label: "Tài khoản",
        key: "manageSystems/users",
        className: "!text-white !m-0 !rounded-none !w-full",
      },
      {
        label: "Trạm đo",
        key: `${baseRouter.station.split("/")[1]}`,
        className: "!text-white !m-0 !rounded-none !w-full ",
        theme: "dark",
        children: [
          {
            label: "Danh sách trạm đo",
            key: `${baseRouter.station.split("/")[1]}`,
            className: "!text-white !m-0 !rounded-none !w-full ",
          },
          // {
          //   label: "Danh sách camera",
          //   key: "camera",
          //   className: "!text-white !m-0 !rounded-none !w-full ",
          // },
        ],
      },
      {
        label: "Ticket sự cố",
        key: `${baseRouter.tickets.split("/")[1]}`,
        className: "!text-white !m-0 !rounded-none !w-full ",
      },
      // {
      //   label: "SMS đa kênh",
      //   key: `${baseRouter.sms.split("/")[1]}`,
      //   className: "!text-white !m-0 !rounded-none !w-full ",
      //   theme: "dark",
      //   children: [
      //     {
      //       label: "Cấu hình tin nhắn",
      //       key: `${baseRouter.sms.split("/")[1]}`,
      //       className: "!text-white !m-0 !rounded-none !w-full",
      //     },
      //     {
      //       label: "Cấu hình số điện thoại",
      //       key: `${baseRouter.sms.split("/")[1]}/${
      //         baseRouter.smsGroupPhone.split("/")[1]
      //       }`,
      //       className: "!text-white !m-0 !rounded-none !w-full",
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   label: "Báo cáo số liệu",
  //   key: "station",
  //   icon: <CommonIcons.User />,
  //   className: "!text-white !m-0 !rounded-none !w-full ",
  // },
];

export const dataTimes: TOption[] = [
  {
    label: "Time",
    value: "time",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Week",
    value: "week",
  },
  {
    label: "Month",
    value: "month",
  },
  {
    label: "Quarter",
    value: "quarter",
  },
  {
    label: "Year",
    value: "year",
  },
];

export const filterRealtime: TOption[] = [
  { label: "1 second", value: 1000 },
  { label: "5 seconds", value: 5000 },
  { label: "10 seconds", value: 10000 },
  { label: "15 seconds", value: 15000 },
  { label: "30 seconds", value: 30000 },
  { label: "1 minutes", value: 60000 },
  { label: "2 minutes", value: 120000 },
  { label: "5 minutes", value: 300000 },
  { label: "10 minutes", value: 600000 },
  { label: "15 minutes", value: 900000 },
  { label: "30 minutes", value: 1800000 },
  { label: "1 hour", value: 3600000 },
  { label: "2 hours", value: 7200000 },
  { label: "5 hours", value: 18000000 },
  { label: "10 hours", value: 36000000 },
  { label: "12 hours", value: 43200000 },
  { label: "1 day", value: 86400000 },
  { label: "7 day", value: 604800000 },
  { label: "30 days", value: 2592000000 },
];

export const SCOPE = {
  CLIENT_SCORE: "CLIENT_SCORE",
  SERVER_SCOPE: "SERVER_SCOPE",
  SHARED_SCOPE: "SHARED_SCOPE",
};

export const statusTicket = {
  receive: "Chưa tiếp nhận",
  pending: "Đang xử lý",
  done: "Hoàn thành",
};

export const TicketStatus: TOption[] = [
  { label: statusTicket.receive, value: statusTicket.receive },
  { label: statusTicket.pending, value: statusTicket.pending },
  { label: statusTicket.done, value: statusTicket.done },
];

export const ID_WS = {
  CrackMetter: 1,
  Intensity: 2,
  VectorGal: 3,
  Ovarall: 4,
};

export const optionsTimeFilter: TOption[] = [
  {
    label: "Theo giờ",
    value: "time",
  },
  {
    label: "Theo ngày",
    value: "date",
  },
  {
    label: "Theo tháng",
    value: "month",
  },
];

export const Role = [
  { role: "CUSTOMER_USER", name: "Khách hàng" },
  { role: "TENANT_ADMIN", name: "Quản trị viên" },
];

export const optionStationTypes = [
  { label: "--Tất cả--", value: "" },
  { label: "Trạm sạt lở", value: "CDRC" },
  { label: "Trạm lũ quét", value: "LMMN" },
];
export const optionStatusStation = [
  { label: "--Tất cả--", value: "" },
  { label: "Bình thường", value: 1 },
  { label: "Cảnh báo", value: 2 },
];
export const optionStatusOnOff: TOption[] = [
  { label: "--Tất cả--", value: "" },
  { label: "Online", value: 1 },
  { label: "Offline", value: 0 },
];

export const optionQueryNotification: TOption[] = [
  { label: "Chưa đọc", value: 1 },
  { label: "Đã đọc", value: 0 },
];

export const stationTypeDefault = [
  { type: "LMMN", name: "Lũ lụt" },
  { type: "CDRC", name: "Sạt lở" },
];

export const ColorLineChart = [
  "#FDB64E",
  "#D671AD",
  "#2F80ED",
  "#F68567",
  "#8EC975",
  "#e15759",
];

export const filterDateChart: TOption[] = [
  {
    label: "24 giờ qua",
    value: 86400000,
  },
  {
    label: "7 ngày qua",
    value: 604800000,
  },
  {
    label: "90 ngày qua",
    value: 7776000000,
  },
];
export const filterDateChartRealTime: TOption[] = [
  {
    label: "Hiện tại",
    value: "real",
  },
  {
    label: "24 giờ qua",
    value: 86400000,
  },
  {
    label: "7 ngày qua",
    value: 604800000,
  },
  {
    label: "90 ngày qua",
    value: 7776000000,
  },
];

export const optionsRole: TOption[] = [
  { label: "Người vận hành", value: "config" },
  { label: "Người dùng", value: "customer" },
];

export enum Roles {
  ADMIN = "admin",
  CONFIG = "config",
  CUSTOMER = "customer",
}

export const optionsContentSMSCDRC: TOption[] = [
  {
    label: "Mã trạm",
    value: "code",
  },
  {
    label: "Tên trạm",
    value: "stationName",
  },
  {
    label: "Ngày đo",
    value: "date",
  },
  {
    label: "Giờ đo",
    value: "hour",
  },
  {
    label: "Độ dài hiện tại",
    value: "crackMetter",
  },
  {
    label: "Gia tốc rung chấn",
    value: "vectorGal",
  },
];

export const optionsContentSMSLMMN: TOption[] = [
  {
    label: "Mã trạm",
    value: "code",
  },
  {
    label: "Tên trạm",
    value: "stationName",
  },
  {
    label: "Ngày đo",
    value: "date",
  },
  {
    label: "Giờ đo",
    value: "hour",
  },
  {
    label: "Lượng mưa/h",
    value: "rain",
  },
  {
    label: "Tổng lương mưa",
    value: "totalRain",
  },
  {
    label: "Mực nước",
    value: "waterLevel",
  },
];
