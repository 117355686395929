import React from "react";
import { CommonStyles } from "../../../components/ui";
import baseRouter from "../../../router/baseRouter";
import { useParams } from "react-router-dom";
import CommonIcons from "../../../assets/icons";
import Alarm from "../../../components/detailStation/Alarm";
import InfoStation from "../../../components/station/stationSettings/Info";
import Ticket from "../../../components/station/stationSettings/Ticket";
import TimeSendData from "../../../components/station/stationSettings/TimeSendData";
import Email from "../../../components/station/stationSettings/Email";
import CDRCDevice from "../../../components/station/stationSettings/Device/CDRCDevice";
import CDRCSoftware from "../../../components/station/stationSettings/Software/CDRCSoftware";
import Locate from "../../../components/station/stationSettings/Locate";
import Phone from "../../../components/station/stationSettings/Phone";
import SMS from "../../../components/station/stationSettings/SMS";
import AlarmSwitch from "../../../components/station/stationSettings/Alarm";
import useGetDetailDevice from "../../../modules/device/hooks/useGetDetailDevice";
import { CDRC, Role, Roles } from "../../../constants/Index";
import LMMNDevice from "../../../components/station/stationSettings/Device/LMMNDevice";
import LMMNSoftware from "../../../components/station/stationSettings/Software/LMMNSoftware";
import Address from "../../../components/station/stationSettings/Address";
import ListDevice from "../../../components/station/stationSettings/ListDevice";
import ColorStation from "../../../components/station/stationSettings/ColorStation";
import TimeAlarm from "../../../components/station/stationSettings/TimeAlarm";
import { useAppSelector } from "../../../hooks/hooks";

export default function StationSettings() {
  //! state
  const params = useParams();
  const idStation = params.id;
  const { data: dataDevice, isLoading: loadingDevice } = useGetDetailDevice(
    String(idStation)
  );

  const user = useAppSelector((state) => state.user.user);
  //! function
  //! render

  if (loadingDevice) {
    <CommonStyles.LoadingUI />;
  }
  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Cấu hình trạm đo"
        subTitle={[
          {
            title: "Quản lý hệ thống",
            link: baseRouter.station,
          },
          {
            title: "Trạm đo",
            link: baseRouter.station,
          },
          {
            title: "Danh sách trạm đo",
            link: baseRouter.station,
          },
          {
            title: "Cấu hình trạm đo",
            link: `${baseRouter.stationSettings}/${idStation}`,
          },
        ]}
        extra={
          <Alarm idStation={String(idStation)} type={dataDevice?.data.type} />
        }
      />
      <div className="grid md:grid-cols-2 gap-3">
        <InfoStation idStation={String(idStation)} />
        <div className="grid  grid-cols-3 gap-3">
          <Ticket idStation={String(idStation)} />
          <TimeSendData idStation={String(idStation)} />
          <TimeAlarm idStation={String(idStation)} />
        </div>

        <Locate idStation={String(idStation)} />
        <Address idStation={String(idStation)} />

        <div className="grid grid-cols-2 gap-3">
          <SMS idStation={String(idStation)} />
          <AlarmSwitch idStation={String(idStation)} />
        </div>
        <Email idStation={String(idStation)} />
        <>
          {user?.lastName !== Roles.CUSTOMER ? (
            dataDevice?.data.type === CDRC ? (
              <>
                <CDRCDevice idStation={String(idStation)} />
                <CDRCSoftware idStation={String(idStation)} />
              </>
            ) : (
              <>
                <LMMNDevice idStation={String(idStation)} />
                <LMMNSoftware idStation={String(idStation)} />
              </>
            )
          ) : null}
        </>
        {/* {dataDevice?.data.type === CDRC ? (
          <>
            <CDRCDevice idStation={String(idStation)} />
            <CDRCSoftware idStation={String(idStation)} />
          </>
        ) : (
          <>
            <LMMNDevice idStation={String(idStation)} />
            <LMMNSoftware idStation={String(idStation)} />
          </>
        )} */}
      </div>
      {user?.lastName !== Roles.CUSTOMER ? (
        <>
          <div className="grid md:grid-cols-2 gap-3">
            <Phone idStation={String(idStation)} />
            <ColorStation idStation={String(idStation)} />
          </div>

          <ListDevice
            idStation={String(idStation)}
            type={dataDevice?.data.type}
          />
        </>
      ) : (
        <>
          <Phone idStation={String(idStation)} />
        </>
      )}
    </div>
  );
}
