import React, { useEffect, useState } from "react";
import useGetNotifications from "../../../modules/notifications/hooks/useGetNotifications";
import {
  getCreatedTime,
  getErrorMsg,
  parseQueryString,
  showMsg,
} from "../../../helpers";
import { CommonStyles } from "../../ui";
import baseRouter from "../../../router/baseRouter";
import CommonIcons from "../../../assets/icons";
import { INotification } from "../../../modules/notifications/notification.interface";
import { optionQueryNotification } from "../../../constants/Index";
import useToggle from "../../../hooks/useToggle";
import _ from "lodash";
import { useAppDispatch } from "../../../hooks/hooks";
import notificationService from "../../../modules/notifications/notification.service";
import httpService from "../../../services/httpService";

export default function ListNotification() {
  //! state
  const { open, shouldRender, toggle } = useToggle();
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState({
    page: 0,
    pageSize: 10,
    unreadOnly: 1,
    sortOrder: "DESC",
  });

  const { data, isLoading, refetch } = useGetNotifications(
    parseQueryString(filters),
    false
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataTable, setDataTable] = useState<INotification[]>([]);
  //! function
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      render: (_: any, record: INotification, index: number) => {
        return filters.page * filters.pageSize + 1 + index;
      },
    },
    {
      title: "Tiêu đề thông báo",
      dataIndex: "subject",
    },
    {
      title: "Thiết bị",
      dataIndex: "text",
    },
    {
      title: "Thời gian",
      dataIndex: "createdTime",
      render: (_: any, record: INotification) => {
        return <p>{getCreatedTime(record.createdTime)}</p>;
      },
    },
    {
      title: "Cảnh báo",
      dataIndex: "warning",
      render: (_: any, record: INotification) => {
        const danger = record.info.action === "created" ? true : false;
        return danger ? (
          <CommonStyles.ButtonUI danger ghost>
            Nguy cấp
          </CommonStyles.ButtonUI>
        ) : null;
      },
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      render: (_: any, record: INotification, index: number) => {
        return (
          <CommonStyles.ButtonAction
            handleDelete={async () => {
              try {
                const result = await notificationService.deleteNotification(
                  record.id.id
                );
                if (result.status === 200) {
                  dispatch(showMsg("success", "Thành công"));
                  refetch();
                }
              } catch (error) {
                dispatch(showMsg("error", getErrorMsg(error)));
              }
            }}
            // moreItems={[
            //   {
            //     key: "read",
            //     label: "Đánh dấu đã đọc",
            //     action: async () => {
            //       handleRead(record.id.id);
            //     },
            //   },
            // ]}
          />
        );
      },
    },
  ];
  const onChangePage = (value: any) => {
    setFilters((prev) => ({ ...prev, page: value - 1 }));
  };
  const onChangeSelect = (value: any) => {
    setFilters((prev) => ({ ...prev, unreadOnly: value }));
  };
  // const handleRead = async (id: string) => {
  //   try {
  //     const result = await notificationService.readNotification(id);
  //     if (result.status === 200) {
  //       dispatch(showMsg("success", "Thành công"));
  //       refetch();
  //     }
  //   } catch (error) {
  //     dispatch(showMsg("error", getErrorMsg(error)));
  //   }
  // };
  const handleDelete = async () => {
    try {
      for (const item of selectedRowKeys) {
        const record = dataTable[Number(item)];
        const result = await notificationService.deleteNotification(
          record.id.id
        );
      }
      dispatch(showMsg("success", "Thành công"));
      toggle();
      refetch();
      setSelectedRowKeys([]);
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    refetch && refetch();
  }, [filters]);

  useEffect(() => {
    if (!!data?.data.data) {
      const addKeyToObjects = data.data.data.map((item, index) => {
        return { ...item, key: index.toString() };
      });
      setDataTable(addKeyToObjects);
    }
  }, [isLoading, data]);
  //! render
  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Danh sách thông báo"
        subTitle={[
          {
            title: "Danh sách thông báo",
            link: baseRouter.camera,
          },
        ]}
      />
      <div>
        <div className="flex justify-end mb-3">
          {/* <CommonStyles.SelectUI
            label="Trạg thái"
            horizontal
            options={optionQueryNotification}
            value={filters.unreadOnly}
            onSelect={onChangeSelect}
          /> */}
          <div className="flex gap-2">
            <CommonStyles.ButtonUI
              danger
              Icons={<CommonIcons.DeleteOutlined />}
              disabled={selectedRowKeys.length <= 0}
              onClick={toggle}
            >
              Xoá tất cả
            </CommonStyles.ButtonUI>
            {/* <CommonStyles.ButtonUI Icons={<CommonIcons.Check />}>
              Đánh dấu tất cả đã đọc
            </CommonStyles.ButtonUI> */}
          </div>
        </div>
        <CommonStyles.TableUI
          rowSelection={rowSelection}
          loading={isLoading}
          dataSource={dataTable ?? []}
          columns={columns}
          scroll={{
            x: 1200,
          }}
        />

        <CommonStyles.PaginationUI
          page={filters.page + 1}
          pageSize={filters.pageSize}
          totalElement={data?.data.totalElements}
          onChangePage={onChangePage}
        />
      </div>

      {shouldRender && (
        <CommonStyles.ModalUI
          open={open}
          toggle={toggle}
          type="error"
          title={`Bạn có muốn xóa ${selectedRowKeys.length} thông báo ?  `}
          onConfirm={handleDelete}
          verifyDelete={true}
        />
      )}
    </div>
  );
}
