import { useTranslation } from "react-i18next";
import CommonIcons from "../../../assets/icons";
import { ENTITY_TYPE, SCOPE } from "../../../constants/Index";
import ResponseUI from "../../../constants/response";
import { getErrorMsg, parseQueryString, showMsg } from "../../../helpers";
import { useAppDispatch } from "../../../hooks/hooks";
import useToggle from "../../../hooks/useToggle";
import useGetAttributeKey from "../../../modules/telemetry/hooks/useGetAttributeKey";
import telemetryService from "../../../modules/telemetry/telemetry.service";
import { CommonStyles } from "../../ui";
interface IProps {
  idStation: string;
  type?: string;
}
export default function Alarm(props: IProps) {
  //! station
  const { idStation, type } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { open, shouldRender, toggle } = useToggle();
  const { data, isLoading } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "TimeAlarm,Sethigh" })
  );
  //! setHeight for CDRC => ( cuong do rung chan < 0 -> alram )

  //! function
  const handleAPI = async (status: string) => {
    const postAlarm = await telemetryService.postDeviceTele(
      idStation,
      SCOPE.SHARED_SCOPE,
      {
        out3: status,
      }
    );
    return postAlarm;
  };
  const toggleAlarm = () => {
    toggle();
  };
  const handleAlarm = async () => {
    try {
      let dataHeightCDRC = data?.data
        .find((item) => item.key === "Sethigh")
        ?.value.split(",");
      //! save old value
      let saveCDRC = "";
      if (type === "CDRC") {
        saveCDRC = dataHeightCDRC[2];
      } else {
        saveCDRC = dataHeightCDRC[4];
      }
      //! set new value to alarm active
      if (type === "CDRC") {
        dataHeightCDRC[2] = "-1000";
      } else {
        dataHeightCDRC[4] = "-1000";
      }

      const timeMilisecondAlarm = data && data.data[0]?.value * 1000;
      const resOnOff = await handleAPI("on");
      const postSoftware = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        {
          Sethigh: dataHeightCDRC.toString(),
        }
      );

      setTimeout(async () => {
        const resOff = await handleAPI("off");
        if (type === "CDRC") {
          dataHeightCDRC[2] = saveCDRC;
        } else {
          dataHeightCDRC[4] = saveCDRC;
        }
        const postSoftware = await telemetryService.postDeviceTele(
          idStation,
          SCOPE.SHARED_SCOPE,
          {
            Sethigh: dataHeightCDRC.toString(),
          }
        );
        if (resOff.status === 200) {
          dispatch(showMsg("success", "Báo động đã tắt"));
        }
      }, Number(Number(timeMilisecondAlarm)));
      toggle();

      return;

      const res = await handleAPI("on");
      if (res.status === 200) {
        toggle();
        dispatch(
          showMsg("success", "Báo động đã bật và sẽ tự động tắt sau 30s")
        );
        setTimeout(async () => {
          const resOff = await handleAPI("off");
          if (resOff.status === 200) {
            dispatch(showMsg("success", "Báo động đã tắt"));
          }
        }, 30000);
      }
    } catch (error) {
      dispatch(showMsg("error", t(`${getErrorMsg(error)}`)));
    }
  };
  //! render
  return (
    <>
      <CommonStyles.ButtonUI
        Icons={<CommonIcons.Bell style={{ color: "white" }} />}
        danger
        onClick={toggleAlarm}
        className={ResponseUI.button}
      >
        Báo động
      </CommonStyles.ButtonUI>
      {shouldRender && (
        <CommonStyles.ModalUI
          open={open}
          type="error"
          toggle={toggle}
          title="Bạn muốn bật báo động ?"
          onConfirm={handleAlarm}
          verifyDelete={true}
        />
      )}
    </>
  );
}
