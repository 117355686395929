import { CommonStyles } from "../../../ui";
import { IRelation } from "../../../../modules/relation/relation.interface";
import { useCallback, useEffect, useState } from "react";
import { SCOPE } from "../../../../constants/Index";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { getTimeStampHour, parseQueryString } from "../../../../helpers";
import moment from "moment";
import _, { isEmpty } from "lodash";
import {
  getDataFilterDate,
  valueIncreaseByFilterDate,
} from "../../../../helpers/filterDate";
import { IDateFilter } from "../../../../interface/IDateFilter";
import { useGetLabelsChart } from "../../../../hooks/useGetLabelChart";
import { TelemetryValue } from "../../../../modules/telemetry/telemetry.interface";

import { IAttributeScope } from "../../../../modules/telemetry/attributeScope.interface";
import { segment } from "../../../../helpers/chart";

interface IProps {
  title: string;
  dataStation?: IRelation[];
  dateFilter: IDateFilter;
}
interface IDataTime {
  hour: number;
  date: number;
  month: number;
  year?: number;
  data: TelemetryValue;
}
interface IDataChart {
  station?: string;
  dataChart?: IDataTime[];
  dataLow_High?: IAttributeScope[];
  color?: string;
}
export default function CrackMetter(props: IProps) {
  //! state
  const { title, dataStation, dateFilter } = props;
  const [loading, setLoading] = useState(false);
  const [dataChart, setDataChart] = useState<IDataChart[]>([]);
  const { labelChart } = useGetLabelsChart(
    dataChart[0]?.dataChart,
    dateFilter,
    loading,
    dataChart.length > 0
  );
  const [lineChoice, setLineChoice] = useState<IRelation[]>([]);
  const [statusSwitch, setStatusSwitch] = useState(true);
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [filterOptions, setFilterOptions] = useState<any>([]);
  //! function

  const optionsFilter = useCallback(async () => {
    if (!dataStation || dataStation.length === 0) {
      return [];
    }

    const options = await Promise.all(
      dataStation.map(async (item, index) => {
        const dataColor = await telemetryService.getAttributeScope(
          item?.to?.entityType,
          item?.to?.id,
          SCOPE.SHARED_SCOPE,
          parseQueryString({ keys: "colorChart" })
        );
        return {
          label: item.toName.split("/")[1],
          value: item.toName.split("/")[1],
          color: dataColor.data[0]?.value,
        };
      })
    );

    return options;
  }, [dataStation]);

  const [filterStation, setFilterStation] = useState([]);

  const lineChecked = useCallback(() => {
    if (lineChoice && lineChoice?.length <= 0) {
      return [];
    }

    return lineChoice?.map((item, index) => {
      return {
        label: item.toName.split("/")[1],
        value: item.toName.split("/")[1],
      };
    });
  }, [lineChoice]);

  useEffect(() => {
    if (filterStation && filterStation?.length > 0) {
      const stations: any = [];
      filterStation?.map((item: any) => {
        const station: any = dataStation?.find(
          (element) => element.toName.split("/")[1] === item?.value
        );
        stations.push(station);
      });
      setLineChoice(stations);
    }
    if (filterStation && filterStation?.length === 0) {
      setLineChoice([]);
    }
  }, [filterStation]);

  useEffect(() => {
    setLineChoice(_.take(dataStation, 1));
  }, [dataStation]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const options = await optionsFilter();
        setFilterOptions(options);
      } catch (error) {
        // Handle any errors that occur during the fetching of options
      } finally {
        setLoadingOptions(false);
      }
    };

    fetchOptions();
  }, [dataStation]);

  // useEffect(() => {
  //   if (dataStation && dataStation?.length > 0) {
  //     const options: any = dataStation?.map((item, index) => {
  //       return {
  //         label: item.toName.split("/")[1],
  //         value: item.toName.split("/")[1],
  //       };
  //     });
  //     setFilterStation(options);
  //   }
  // }, [dataStation]);

  useEffect(() => {
    const dataDevice = async () => {
      const increaseTime = valueIncreaseByFilterDate(dateFilter);
      if (lineChoice && lineChoice.length > 0) {
        let data: IDataChart[] = [];

        for (const element of lineChoice) {
          setLoading(true);
          const dataLow_High = await telemetryService.getAttributeKey(
            element?.to?.entityType,
            element?.to?.id,
            parseQueryString({ keys: "Setlow,Sethigh" })
          );
          const dataColor = await telemetryService.getAttributeScope(
            element?.to?.entityType,
            element?.to?.id,
            SCOPE.SHARED_SCOPE,
            parseQueryString({ keys: "colorChart" })
          );

          let startTime = dateFilter.startDate;

          let dataElement: IDataTime[] = [];
          let check = true;
          if (dateFilter.type === "second") {
            const detailStation = await telemetryService.getTimeSeries(
              element?.to?.entityType,
              element?.to?.id,
              parseQueryString({
                startTs: dateFilter.startDate,
                endTs: dateFilter.endDate,
                limit: 50000,
                useStrictDataTypes: true,
                keys: "data",
                orderBy: "DESC",
              })
            );
            if (detailStation?.data?.data) {
              detailStation?.data?.data.map((item: any, index: any) => {
                const dataOrder = getDataFilterDate(
                  moment(item.ts).valueOf(),
                  item.value
                );
                dataElement.push(dataOrder);
              });
            }
          } else {
            while (startTime <= dateFilter.endDate && check) {
              if (startTime > dateFilter.endDate) {
                startTime = dateFilter.endDate;
                check = false;
              }
              const start = getTimeStampHour(startTime, "start", dateFilter);
              const end = getTimeStampHour(startTime, "end", dateFilter);
              const detailStation = await telemetryService.getTimeSeries(
                element?.to?.entityType,
                element?.to?.id,
                parseQueryString({
                  startTs: start,
                  endTs: end,
                  limit: 1,
                  useStrictDataTypes: true,
                  keys: "data",
                  orderBy: "DESC",
                })
              );
              if (detailStation?.data?.data) {
                const dataOrder = getDataFilterDate(
                  start,
                  detailStation?.data?.data[0]?.value
                );
                dataElement.push(dataOrder);
              }

              startTime += increaseTime;
            }
          }

          data.push({
            station: element.toName,
            dataChart: dataElement,
            dataLow_High: dataLow_High.data,
            color: dataColor.data[0]?.value,
          });
        }
        setLoading(false);
        setDataChart(data);
      }
      if (lineChoice && lineChoice.length === 0) {
        setDataChart([]);
      }
    };
    dataDevice();
  }, [lineChoice, dateFilter]);

  const generateDataChart = () => {
    const dataLine: any = [];
    dataChart.map((item: IDataChart) => {
      const high =
        !!item && item.dataLow_High?.find((el) => el.key === "Sethigh");
      const low =
        !!item && item.dataLow_High?.find((el) => el.key === "Setlow");
      let data: any = [];

      if (!!item.dataChart) {
        item.dataChart.map((child: any) => {
          data.push(
            child?.data["CrackMetter"]?.status
              ? child?.data["CrackMetter"]?.value
              : NaN
          );
        });
      }
      dataLine.push({ high, low, data, color: item?.color });
    });
    let lineStation: any = [];
    dataLine?.map((line: any, index: any) => {
      const lineData = {
        label: dataChart[index]?.station?.split("/")[1],
        data: line?.data,
        borderColor: line?.color,
        cubicInterpolationMode: "monotone",
        lineTension: 0,
        borderWidth: 1.5,
        segment: segment,
        spanGaps: true,
      };

      // const lineHigh = {
      //   label: `Ngưỡng cao ${dataChart[index]?.station?.split("/")[1]}`,
      //   data: Array.from({ length: line?.data?.length }, () =>
      //     Number(line?.high?.value.split(",")[0])
      //   ),
      //   borderColor: line?.color,
      //   cubicInterpolationMode: "monotone",
      //   lineTension: 0,
      //   borderWidth: 1.5,
      //   borderDash: [5, 5],
      // };
      // const lineLow = {
      //   label: `Ngưỡng thấp ${dataChart[index]?.station?.split("/")[1]}`,
      //   data: Array.from({ length: line?.data?.length }, () =>
      //     Number(line?.low?.value)
      //   ),
      //   borderColor: line?.color,
      //   cubicInterpolationMode: "monotone",
      //   lineTension: 0,
      //   borderWidth: 1.5,
      //   borderDash: [5, 5],
      // };
      lineStation.push(lineData);
      // lineStation.push(lineHigh);
      // lineStation.push(lineLow);
    });
    return lineStation;
  };
  const onChangeSwitch = (value: any) => {
    setStatusSwitch(value);
  };
  //! render
  const ExtraFilter = () => {
    return (
      <div className="flex gap-2">
        <CommonStyles.SwichUI
          checked={statusSwitch}
          onChange={onChangeSwitch}
          size="small"
          label="Vượt ngưỡng"
        />
      </div>
    );
  };

  return (
    <CommonStyles.CardResize
      title={title}
      // extra={ExtraFilter()}
      className="border relative"
    >
      {loading && (
        <div
          className={`absolute left-0 bottom-0 bg-white  w-full h-full flex justify-center items-center ${
            loading && "opacity-50"
          }`}
        >
          <CommonStyles.LoadingUI />
        </div>
      )}
      <div>
        <CommonStyles.LineChart
          labels={labelChart}
          dataChart={
            statusSwitch
              ? generateDataChart()
              : generateDataChart().filter(
                  (_: any, index: number) => index % 3 === 0
                )
          }
          displayLengend={false}
        />
        <CommonStyles.FiltersUI
          checkedList={lineChecked() ?? []}
          options={loadingOptions ? [] : filterOptions}
          setCheckedList={setFilterStation}
        />
      </div>
    </CommonStyles.CardResize>
  );
}
