import { DatePicker } from "antd";
import moment from "moment";
import CommonIcons from "../../../assets/icons";
import {
  ASSET_CDRC,
  ASSET_LMMN,
  ASSET_TYPE,
  filterDateChart,
} from "../../../constants/Index";
import { TOption } from "../../../interface";
import { useGetRelationInfo } from "../../../modules/relation/hooks/useGetRelationInfo";
import { CommonStyles } from "../../ui";
import Rain from "./Rain";

import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import useFilterDate from "../../../hooks/useFilterDate";
import CrackMetter from "./CrackMetter/CrackMetter";
import VectorGal from "./VectorGal";
import WaterLevel from "./WaterLevel";

export default function OverallChart() {
  //! state

  const { data: dCDRC } = useGetRelationInfo({
    fromId: ASSET_CDRC,
    fromType: ASSET_TYPE,
  });
  const { data: dLMMN } = useGetRelationInfo({
    fromId: ASSET_LMMN,
    fromType: ASSET_TYPE,
  });

  //! function
  const generateOptionsDate = () => {
    let options = filterDateChart;
    const currentMonth = moment.utc().month() + 1;
    const item: TOption[] = [
      {
        label: `Tháng ${currentMonth - 1}`,
        value: currentMonth - 2,
      },
      {
        label: `Tháng ${currentMonth - 2}`,
        value: currentMonth - 3,
      },
      {
        label: `Tháng ${currentMonth - 3}`,
        value: currentMonth - 4,
      },
      {
        label: "Tuỳ chỉnh",
        value: "setting",
      },
    ];
    options = [...options, ...item];

    return options;
  };

  const { dateFilter, dateSetting, onChangeFilterDate, onChangeSettingDate } =
    useFilterDate(generateOptionsDate());
  //! render
  const renderExtraAction = () => {
    const format = "DD/MM";

    const start = moment.utc(dateFilter.startDate).format(format);
    const end = moment.utc(dateFilter.endDate).format(`${format}/YYYY`);
    const disabledDate: RangePickerProps["disabledDate"] = (current) => {
      // Can not select days after today
      return current && current > dayjs().endOf("day");
    };

    return (
      <div>
        <p className="font-medium">{`${start} - ${end}`}</p>
        <CommonStyles.SelectUI
          value={dateFilter.value}
          options={generateOptionsDate()}
          onChange={onChangeFilterDate}
        />
        {dateSetting && (
          <DatePicker.RangePicker
            allowClear={false}
            onChange={onChangeSettingDate}
            disabledDate={disabledDate}
          />
        )}
      </div>
    );
  };
  return (
    <CommonStyles.CardResize
      Icon={CommonIcons.LineChart}
      title="Biểu đồ báo cáo toàn hệ thống"
      extra={renderExtraAction()}
    >
      <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-5">
        <Rain
          title="Lượng mưa (mm)"
          dataStation={dLMMN?.data}
          dateFilter={dateFilter}
        />
        <WaterLevel
          title="Độ sâu mực nước (cm)"
          dataStation={dLMMN?.data}
          dateFilter={dateFilter}
        />

        <CrackMetter
          title="Căng kế (cm)"
          dataStation={dCDRC?.data}
          dateFilter={dateFilter}
        />
        <VectorGal
          title="Gia tốc Rung chấn (gal)"
          dataStation={dCDRC?.data}
          dateFilter={dateFilter}
        />
      </div>
    </CommonStyles.CardResize>
  );
}
