import { Form } from "antd";
import React, { useEffect } from "react";
import { CommonStyles } from "../../../../ui";
import useGetDetailDevice from "../../../../../modules/device/hooks/useGetDetailDevice";
import deviceService from "../../../../../modules/device/device.service";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { getErrorMsg, showMsg } from "../../../../../helpers";
import ResponseUI from "../../../../../constants/response";

interface IProps {
  idStation: string;
  toggle: () => void;
  refetch: () => void;
}
export default function EditModalStationName(props: IProps) {
  //! state
  const { idStation, toggle, refetch } = props;
  const dispatch = useAppDispatch();
  const { data, isLoading: loadingDevice } = useGetDetailDevice(idStation);
  const dataDevice = data?.data;
  const [form] = Form.useForm();
  const initialValues = {
    code: dataDevice?.name.split("/")[0] ?? "",
    name: dataDevice?.name.split("/")[1] ?? "",
  };
  //! function
  const handleSubmit = async (values: any) => {
    const valuePost = {
      ...data?.data,
      name: `${values.code}/${values.name}`,
    };
    try {
      const updateStation = await deviceService.createAndUpdate(valuePost);
      if (updateStation.status === 200) {
        dispatch(showMsg("success", "Thành công"));
        refetch();
        toggle();
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);
  //! render
  return (
    <Form
      className="min-w-80"
      name="editStationForm"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <div className="md:grid  gap-3 mt-8">
        <CommonStyles.FormFieldUI
          name={"code"}
          label="Mã trạm"
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />

        <CommonStyles.FormFieldUI
          name={"name"}
          label="Tên trạm"
          component={
            <CommonStyles.InputUI
              maxLength={50}
              className="h-9 border !border-s-line active:!border-main-color"
            />
          }
        />
      </div>

      <div className="flex justify-end items-center gap-4">
        <CommonStyles.ButtonUI
          className={`${ResponseUI.button} w-24`}
          danger
          ghost
          onClick={toggle}
        >
          Hủy
        </CommonStyles.ButtonUI>

        <CommonStyles.ButtonUI
          className={`${ResponseUI.button} w-24`}
          action="submit"
          loading={loadingDevice}
        >
          Lưu
        </CommonStyles.ButtonUI>
      </div>
    </Form>
  );
}
